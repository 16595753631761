body.lb-body .bgrblue{
    background-color: #277394;
	position: fixed;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
}

.region{
    stroke: #fff;
    stroke-width: 0.1;
    cursor: pointer;
    transform-origin: 50% 50%;
}

.region.sel, .region:hover.sel{
    fill: #800 !important;
}

.region:hover{
    fill: #866 !important;
}

.border{
    stroke: #fff;
    fill: #ddd;   
    stroke-width: 0.1;
}